<script>
  import { onMount } from 'svelte';
  import Alert from '../components/Alert.svelte';
  import Field from './Field.svelte';
  import Button from './Button.svelte';
  import { _ } from 'svelte-i18n';
  import { FormStatuses } from '../constants';

  export let request;
  export let LANGUAGE;

  

  import {
    BOKAMERA_COMPANY_ID,
    RECAPTCHA_SITE_KEY
  } from '../constants';

  export let title = 'Message from the form on the site';

  let Name = '',
    Email = '',
    Phone = '',
    MessageTitle = title,
    MessageBody = '';

  let message,
    status,
    loading = false;

  let touched = {};

  let activeLanguage = LANGUAGE;

  let recaptchaInitialized = false;

  async function handleSubmit(e) {
    const grecaptchaResponse = typeof grecaptcha !== 'undefined' && !window.Cypress ? grecaptcha.getResponse() : 'ok';

    if (!grecaptchaResponse && !window.Cypress) {
      message = $_('common.youMustPassRecaptcha');
      status = FormStatuses.DANGER;
    } else {
      loading = true;
      request({
        url: '/messages/sendemailtocompany',
        method: 'POST',
        data: JSON.stringify({
          CompanyId: BOKAMERA_COMPANY_ID,
          Name,
          Email,
          Phone,
          MessageTitle: `${MessageTitle} - ${Email}`,
          MessageBody,
        }),
      })
        .then((data) => {
          message = $_('contactUs.success');
          status = FormStatuses.SUCCESS;

          resetForm();
        })
        .catch((error) => {
          const { data } = error.response;

          if (data && data.ResponseStatus) {
            if (data.ResponseStatus.Message) {
              message = data.ResponseStatus.Message;
              status = FormStatuses.DANGER;
            }
          }
        })
        .finally(() => {
          loading = false;
        });
    }
  }

  function resetForm() {
    Name = '';
    Email = '';
    Phone = '';
    MessageBody = '';

    touched = {};
  }

  onMount(() => {
    window.__READY_FOR_E2E__ = true;
  });

  function initRecaptcha() {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?hl=${activeLanguage}`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  function handleBlur(e) {
    touched = { ...touched, [e.target.name]: true };

    if (!recaptchaInitialized && !window.Cypress) {
      initRecaptcha();
      recaptchaInitialized = true;
    } else if (window.Cypress) {
      recaptchaInitialized = true
    }
  }
</script>

<style>
  .contact-us__form {
    margin: auto;
    max-width: 800px;
  }
  .contact-us__form :global(.button) {
    width: 100%;
  }
  .alert-container {
    margin-bottom: 20px;
  }
  .recaptcha-container {
    margin-bottom: 20px;
  }

  @media (max-width: 660px) {
    .contact-us__form {
      width: 100%;
    }
  }
</style>

<form
  id="contact-us__form"
  class="contact-us__form"
  on:submit|preventDefault={handleSubmit}>
  <Field
    bind:value={Name}
    label={$_('contactUs.name')}
    id="Name"
    required
    touched={touched.Name}
    onBlur={handleBlur} />
  <Field
    bind:value={Email}
    label={$_('contactUs.email')}
    id="Email"
    type="email"
    required
    touched={touched.Email}
    onBlur={handleBlur} />
  <Field
    bind:value={Phone}
    label={$_('contactUs.phone')}
    id="Phone"
    required
    touched={touched.Phone}
    onBlur={handleBlur} />
  <Field
    bind:value={MessageBody}
    label={$_('contactUs.message')}
    id="MessageBody"
    type="textarea"
    required
    touched={touched.MessageBody}
    onBlur={handleBlur} />
  <div class="field grid">
    <div class="col-3" />
    <div id="recaptcha-container" class="col-6 recaptcha-container">
      <div class="g-recaptcha" data-sitekey={RECAPTCHA_SITE_KEY} />
    </div>
    <div class="col-3" />
  </div>
  {#if message}
    <div class="field grid">
      <div class="col-3" />
      <div class="col-6">
        <div class="alert-container">
          <Alert {message} type={status} />
        </div>
      </div>
    </div>
  {/if}
  <div class="field grid">
    <div class="col-3" />
    <div class="col-6">
      <Button type="submit" {loading} disabled={!recaptchaInitialized}>
        {$_('contactUs.submit')}
      </Button>
    </div>
    <div class="col-3" />
  </div>
</form>
